/* Variables */
:root {
  --itemGrow: 1.05;
  --duration: 250ms;
}

html {
  scroll-behavior: smooth;
}


.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 100%);
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;


  
  /* Soft scrollbars for subtlety */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* Custom colors */

  /* For WebKit-based browsers */
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Background and shadows for style */
  background: transparent rgb(120, 105, 99);
  border-radius: 8px; /* Adds subtle rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

section {
  position: relative;
  display: grid;
  /* grid-template-columns: repeat(5, minmax(0, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 16px;
  margin: 10px 0;
  margin-right: 10px;
}

.item {
  padding: 10px;
  transition: all var(--duration);
  width: 350px; 
  height: 320px; 
  margin-left: 10px;
  box-shadow: 10px solid black;
}

.item:hover {
  /* margin: 0 40px; */
  transform: scale(var(--itemGrow));
}

.arrow__btn {
  position: absolute;
  color: #fff;
  text-decoration: none;
  font-size: 6em;
  background: rgb(0, 0, 0);
  width: 80px;
  padding: 20px;
  text-align: center;
  z-index: 1;
  opacity: 0;
}
.arrow__btn:hover {
  position: absolute;
  color: #fff;
  text-decoration: none;
  font-size: 6em;
  background: rgb(0, 0, 0);
  width: 80px;
  padding: 20px;
  text-align: center;
  z-index: 1;
  transition: 1s;
  opacity: 1;
}

.arrow__btn:nth-of-type(1) {
  top: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  opacity: 0;
}
.arrow__btn:nth-of-type(1):hover {
  top: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  transition: 1s;
  opacity: 1;
  border: none;
  cursor: pointer;
}

.arrow__btn:nth-of-type(2) {
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  opacity: 0;
  
}

.arrow__btn:nth-of-type(2):hover {
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  transition: 1s;
  opacity: 1;
  border: none;
  cursor: pointer;
}

/* Remove the arrow for Mobile */
@media only screen and (max-width: 600px) {
  .arrow__btn {
    display: none;
  }
}