/* src/styling/Starburst.css */
.starburst-original,
.starburst-bottom-right  {
    width: 150px;       
    height: 150px;
    background: url('../assets/images/starburst.png') no-repeat center;
    background-size: contain;
    margin-top: 20px;
    margin: auto;
    animation:  pulse 5s ease-in-out infinite alternate, glow 1.5s ease-in-out infinite alternate;
    z-index: 1000; 
    position: fixed;
    transition: opacity 1s ease;
  }

  .starburst-original:focus,
.starburst-bottom-right:focus  {
  border: none;
  outline: none;
}

.starburst-bottom-right {
  left: auto;
  right: 20px;
  bottom: 20px;
  top: auto;
  transform: none;
  opacity: 0;
  visibility: hidden;
}
.isvisible {
  opacity: 1;
  visibility: visible;
}

.ishidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0s 0.5s;
}

  /* Rotating animation */
  /* @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  } */

  @keyframes gradient {
    100% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
    /* gradient 3s ease infinite, */
  }

  @keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.1); }
  }
  
  /* Glowing animation */
  @keyframes glow {
    0% { filter: drop-shadow(0 0 10px #1E90FF) drop-shadow(0 0 20px #87CEEB); }
    100% { filter: drop-shadow(0 0 20px #87CEEB) drop-shadow(0 0 40px #1E90FF); }
  }