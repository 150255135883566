/* Main overlay for chat modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);  /* Dark transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);  /* Blurs the background */
  z-index: 1100;  /* Ensures the chat window is on top of everything */
}

/* Chat modal box */
.chat-modal {
  background-color: rgb(45, 45, 45);
  width: 400px;
  height: 600px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: auto;
}
.chat-modal h1 {
color: white;
}

.chat-box a {
  color: white; }

/* Close button for the chat window */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Chat box messages */
.chat-box {
  color: white;
  font-weight: bold;
  flex-grow: 1;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #191919;
  border-radius: 5px;
  text-align: left;
}

/* Input area */
.input-area {
  display: flex;
  gap: 10px;
}

.input-area input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.input-area button {
  padding: 10px 15px;
  border: none;
  background-color: #2c2c2c;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.message-content {
  display: block;
  margin-top: 4px;
  line-height: 1.4;
  animation: fadeInText 0.5s ease;
}