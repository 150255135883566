/* Profile page container */
.profile-page {
    padding: 20px;
    transition: margin-left 0.3s ease-in-out;
    color: white;
  }
  
  /* Profile info container */
  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(182, 159, 159, 0.2);
  }
  
  /* Profile picture styling */
  .profile-picture {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }
  
  /* Text styling */
  .profile-info p {
    font-size: 18px;
    margin: 10px 0;
  }

  /* When the sidebar is open, apply margin-left */
.profile-page.sidebar-open {
  margin-left: 250px; /* Shift HomePage to the right when sidebar is open */
}

/* When the sidebar is closed, reset margin-left */
.profile-page.sidebar-closed {
  margin-left: 0; /* Full width when sidebar is closed */
}