/* Settings page container */
.settings-page {
    padding: 20px;
    color: white;
    transition: margin-left 0.3s ease-in-out;
  }
  
  /* Settings options container */
  .settings-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #333;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Individual setting option */
  .settings-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .settings-option label {
    font-size: 18px;
  }

  .settings-page.sidebar-open {
    margin-left: 250px; /* Shift HomePage to the right when sidebar is open */
  }
  
  /* When the sidebar is closed, reset margin-left */
  .settings-page.sidebar-closed {
    margin-left: 0; /* Full width when sidebar is closed */
  }