/* Homepage layout */
.homepage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease-in-out;
  align-items: center;
  text-align: center;
  background-image: url('../assets/images/woodboard.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.h1,h2,h3,h4{
  color: white;
}
.search-bar{
  margin-top: 20px;
  align-self: flex-start;
  padding: 0px;
  display: -webkit-flex;
  margin-left: 100px;
  z-index: 100;
  position: fixed;
}
.personal-noors{
  width: 100%;
}

.carousels{
  width: 100%;
}

.intro-section {
  margin-top: 50px;
}

.starburst-container {
  margin: auto;
  width: 150px;
  height: 150px;
}

.login-button {
  position: absolute; 
  top: 20px; 
  right: 10px;
}

.burger-menu {
  position: fixed;
  top: 20px;
  left: 20px;
  background-color: transparent;
  color: rgb(47, 56, 235);
  font-size: 24px;
  border: none;
  cursor: pointer;
  z-index: 1100;
  transition: all 0.3s ease;
}

.noors-section {
  display: block;
}


/* Grid layout for Noors */
.noors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

/* When the sidebar is open, apply margin-left */
.homepage.sidebar-open {
  margin-left: 250px;
}

.assist-button {
  background-color: #f0f8ff;
  color: #0066cc;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}
.assist-button:hover {
  background-color: #cce6ff;
}

/* Prompt to join */
.join-prompt {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
  color: #aaa;
}

.join-button {
  background-color: #f0f8ff;
  color: #0066cc;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.join-button:hover {
  background-color: #cce6ff;
}