/* Main container */
.copilot-container {
  font-family: 'Segoe UI', sans-serif;
  background-color: transparent;
  color: #323130;
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transition: background-color 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.chat-title {
  margin-bottom: 20px;
  text-align: center;
}

.chat-title h1 {
  font-size: 24px;
  color: #323130;
  font-weight: bold;
}

/* Chat container */
.chat-container {
  background-color: white;
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: fadeIn 0.5s ease-in-out;
  margin-bottom: 40px;
}

/* Chat log */
.chat-log {
  padding: 16px;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  transition: transform 0.2s ease;
}

.chat-log:hover {
  transform: scale(1.01);
}

.chat-message {
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 12px;
  opacity: 0;
  animation: slideIn 0.4s ease forwards;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@keyframes slideIn {
  from {
    transform: translateY(10px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.user-message {
  background-color: #e6f7ff;
  color: #005a9e;
  text-align: right;
  margin-left: 50px;
  transition: all 0.2s ease-in-out;
}

.user-message:hover {
  background-color: #d0efff;
}

.ai-message {
  background-color: #f3f2f1;
  color: #323130;
  margin-right: 50px;
  transition: all 0.2s ease-in-out;
}

.ai-message:hover {
  background-color: #e0e0e0;
}

.message-wrapper {
  display: flex;
  flex-direction: column;
}

.message-author {
  font-weight: bold;
  opacity: 0.7;
  font-size: 12px;
}

.message-content {
  display: block;
  margin-top: 4px;
  line-height: 1.4;
  animation: fadeInText 0.5s ease;
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Input section */
.chat-input-form {

  padding: 16px;
  display: flex;
  animation: fadeIn 0.5s ease-in-out;
}

.input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.chat-input {
  width: 200px;
  flex-grow: 1;
  padding: 14px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  font-size: 16px;
  margin-right: 8px;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}

.chat-input:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-color: #0078d4;
}


.chat-input:focus {
  outline: none;
  background-color: #f0f8ff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border-color: #005a9e;
  transform: scale(1.02);
  background-color: #f0f8ff;
  transition: all 0.5s ease-in-out;
  width: 500px;
}

.send-button {
  background-color: #0078d4;
  border: none;
  color: white;
  padding: 14px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.send-button:hover {
  background-color: #005a9e;
  transform: scale(1.1);
}

.send-icon {
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease;
}

.send-button:hover .send-icon {
  transform: rotate(20deg);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}